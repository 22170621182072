
body,
html {
    height: 100%;
    scroll-behavior: smooth;
}

:root {
    --navbar-height: 60px;
}

.homepage-outer-container {
    height: calc(100vh - var(--navbar-height));
    width: 100vw;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.homepage-container {
    position: relative;
    width: 100%;
    /* Set width to 100% of the viewport width */
    height: 100%;
}

.homepage-image-container {
    position: relative;
    width: 100%;
    /* Scale with the container */
    height: calc(100vh - var(--navbar-height));
    /* Adjust height based on the viewport height minus navbar */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: -50px;
    /* Brings image up */
}

.homepage-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scale(1.0);
}

.homepage {
    text-align: center;
    min-height: 100vh;
    overflow-y: hidden;
}

.hero {
    height: 110vh;
    background-color: #f0f0f0;
    /* Light gray background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-background {
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
}

.space-picture {
    background-position: top;
}

.hero-content {
    position: relative;
    z-index: 1;
    mix-blend-mode: difference;
    color: white;
}

.hero-wrapper {
    position: relative;
    overflow: hidden;
}

.inverted-strip {
    position: absolute;
    background-color: white;
    width: 150%;
    height: 100px;
    transform: rotate(-45deg);
    mix-blend-mode: difference;
    pointer-events: none;
    z-index: 2;
    animation: moveStrip 10s linear infinite;
}

.center-strip {
    transform: rotate(0deg);
    animation: moveStrip 15s linear infinite;
}

.bottom-strip {
    transform: rotate(0deg);
    animation: moveStripInvert 15s linear infinite;
    animation-delay: 3.33s;
}

.rotate-strip {
    transform: rotate(45deg);
    animation: moveStripInvert 15s linear infinite;
    animation-delay: 3.33s;
}

.diagonal-strip-1 {
    top: -10%;
    left: -25%;
}

.diagonal-strip-2 {
    top: -10%;
    left: -25%;
    animation-delay: 3.33s;
}

.diagonal-strip-3 {
    top: 90%;
    left: -25%;
    animation-delay: 6.67s;
}

@keyframes moveStrip {
    0% {
        top: -100%;
    }
    100% {
        top: 100%;
    }
}
@keyframes moveStripInvert {
    0% {
        top: 100%;
    }
    100% {
        top: -100%;
    }
}

.hero-title {
    font-size: 9rem;
    position: relative;
    z-index: 1;
}

.about-me-section,
.resume-section {
    margin-top: -130px;
    position: relative;
    z-index: 3;
}
.about-me-section {
    margin-bottom: 50px;
    z-index: 3;
}
.resume-section {
    z-index: 0;
    margin-top: -180px;
}

.about-me-content,
.resume-content {
    padding: 50px;
    border-radius: 10px;
    max-width: 600px;
    width: 500px;
    margin: 0 auto;
    color: white;
}

.resume-content {
    padding: 0px;
    height: 700px;
    margin-top: 150px;
}

.inverted-text {
    color: white; 
    mix-blend-mode: difference; 
    border-radius: 10px;
    border: 1rem;
    border-color: white;
    padding: 20px;
}

.hero-title {
    font-size: 9rem;
}