.blog-post {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

.blog-post header {
    margin-bottom: 2rem;
}

.blog-post h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
}

.blog-post .author {
    font-style: italic;
    color: #7f8c8d;
}

.blog-post .content {
    font-size: 1.1rem;
}

.blog-post .content h2 {
    font-size: 1.8rem;
    color: #34495e;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.blog-post .content p {
    margin-bottom: 1rem;
}

.blog-post .content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem auto;
}

.blog-post .content blockquote {
    border-left: 4px solid #3498db;
    padding-left: 1rem;
    margin: 1rem 0;
    font-style: italic;
    color: #7f8c8d;
}

@media (max-width: 768px) {
    .blog-post {
        padding: 1rem;
    }

    .blog-post h1 {
        font-size: 2rem;
    }

    .blog-post .content {
        font-size: 1rem;
    }
}
