.blog-list-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    min-width: 1111px;
    overflow: scroll;
}

.blog-list-title {
    text-align: center;
    color: #333;
    margin-bottom: 2rem;
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.blog-card {
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.blog-card h2 {
    color: #2c3e50;
    margin-bottom: 1rem;
}

.blog-excerpt {
    color: #7f8c8d;
    margin-bottom: 1rem;
}

.p-button-text {
    color: #3498db;
}

.blog-data-view .blog-list-item {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    width: 100%;
}
.custom-tooltip {
    max-width: 300px;
    font-size: 0.9rem;
    color: #333;
}
