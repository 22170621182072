.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #1d2b64, #f8cdda);
    padding: 1rem;
    animation: fadeIn 1.5s ease-in;
}

.contact-card {
    background: #fff;
    border-radius: 8px;
    padding: 2rem;
    width: 100%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.8s ease-out;
}

.contact-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 0.5rem;
    animation: textFadeIn 2s ease-in-out;
}

.contact-subtitle {
    font-size: 1.2rem;
    color: #667;
    margin-bottom: 2rem;
}

.contact-label {
    flex: 0 0 80px; /* Fixed width for labels for consistent alignment */
    font-weight: bold;
    color: #555;
    text-align: right; /* Align text to the right */
    margin-bottom: 0; /* Remove bottom margin to align properly */
}

.contact-field {
    display: flex;
    align-items: center; /* Align label and input in the center */
    gap: 1rem; /* Add space between label and input */
    margin-bottom: 1.5rem;
}

.p-invalid {
    border-color: #e74c3c !important;
}

.p-error {
    color: #e74c3c;
    font-size: 0.85rem;
    margin-top: 0.25rem;
    display: block;
}

.p-inputtext, .p-inputtextarea {
    flex: 1; /* Input fields take up the remaining space */
}

.contact-button {
    margin-top: 2rem;
    font-size: 1.2rem;
    width: 100%;
    transition: background 0.3s ease, transform 0.3s ease;
    padding: 0.75rem 1rem;
}

.contact-button:hover {
    background: #1d2b64;
    color: #fff;
    transform: scale(1.05);
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes textFadeIn {
    0% { opacity: 0; transform: translateY(-10px); }
    50% { opacity: 0.5; }
    100% { opacity: 1; transform: translateY(0); }
}
