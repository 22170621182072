
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
  font-size: 11px;
  padding: 0.25rem 1rem;
}


/* Overriding inline styles with !important */
.centered-spinner .p-progress-spinner {
  display: flex !important;
  /* Override the inline-block display from primeReact component*/
}

.container {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@font-face {
  font-family: 'Gotham Rounded Book';
  src: url('../public/GothamRounded-Book.otf') format('truetype');
  /* Add other font properties if needed */
}


.font-kroger {
  font-family: "Gotham Rounded Book";
}

.p-float-label input:focus ~ label {
  color: white;
}

.radio-switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #207f3b !important;
}

.intake-form-textSpan {
  display: block;
  width: 100%;
  height: 3rem;
  background-color: rgb(8, 73, 153) !important;
}

.s-title {
  font-size: 1.75rem;
  color: var(--primary-color); /* Example color for 'text-primary', replace with your project's primary color */
  font-family: "Proxima Nova", sans-serif;
  padding-left:"10px"
}

.Syndigo-Submit-Dialog {
  max-width: '85%';
  min-width: '50%';
  max-height: '85%';
  min-height: '50%';
  justify-content: auto;
  align-content: center;
  overflow: visible;
}

.Dialog-Spinner {
  max-width: '85%';
  min-width: '50%';
  max-height: '85%';
  min-height: '50%';
  justify-content: center;
  vertical-align: auto;
  margin: '100px';
  padding: '100px';
}

.min-width-scroll {
  min-width: 800px;
  overflow-x: hidden;
}
